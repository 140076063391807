body, html, #content, #app {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}

#app {
  display: flex;
  flex-direction: column;
}

#map-container, #map, .flex {
  display: flex;
  flex: 1;
  flex-direction: column;
}

#map-container {
  margin-top: -20px;
}
